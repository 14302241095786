import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbar } from '../../../../redux-store/slices/snackbar.slice';
import { ReduxStore } from '../../../../redux-store/store';
import './notification-snackbar.scss';

export const NotificationSnackbar: React.FC = () => {
  const dispatch = useDispatch();

  const snackbarOpen = useSelector<ReduxStore, boolean>((state) => state.snackbar.open);
  const snackbarMessage = useSelector<ReduxStore, string>((state) => state.snackbar.message);
  const snackbarSeverity = useSelector<ReduxStore, any>((state) => state.snackbar.severety);

  const AUTO_HIDE_DURATION_MS = 3000;

  /** Close snackbar about redux statement */
  const handleClose = (): void => {
    dispatch(hideSnackbar());
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={AUTO_HIDE_DURATION_MS}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
    </Snackbar>
  );
};
