import { TopScorerDto } from '../models/dtos/topscorer.dto';
import { TopScorerTypes } from '../models/enums/topscorer.enum';
import { mwApiInstance } from './axios.middleware';

export const getTopScorer = async (type: TopScorerTypes, limit: number): Promise<TopScorerDto[]> => {
  const topScorerDtos = (await mwApiInstance.get<TopScorerDto[]>(`/top-scorer?type=${type}&limit=${limit}`)).data;
  return topScorerDtos;
};

export const getTopScorerIndice = async (symbol: string, type: TopScorerTypes): Promise<TopScorerDto[]> => {
  const topScorerDtos = (await mwApiInstance.get<TopScorerDto[]>(`/top-scorer/indice?symbol=${symbol}&type=${type}`)).data;
  return topScorerDtos;
};
