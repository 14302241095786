import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PortfolioDto } from '../../modules/portfolio/models/portfolio.dto';

export type PortfolioState = {
  portfolioDtos: PortfolioDto[];
};

const initialState: PortfolioState = {
  portfolioDtos: [],
};

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setPortfolio: (state: PortfolioState, action: PayloadAction<PortfolioDto[]>) => {
      state.portfolioDtos = action.payload;
    },
  },
});

export const { setPortfolio } = portfolioSlice.actions;

export default portfolioSlice.reducer;
