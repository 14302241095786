import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { StockState, setDividendScore } from '../../../../redux-store/slices/stock.slice';
import { ReduxStore } from '../../../../redux-store/store';
import { ScoreModel } from '../../models/score.model';

import { CardTable } from '../../../../core/components/building-blocks/card-table/card-table';
import { Badge } from '../../../../core/components/controls/badge/badge';
import { TableBody } from '../../../../core/components/controls/table/components/table-body';
import { TableCell } from '../../../../core/components/controls/table/components/table-cell';
import { TableHead } from '../../../../core/components/controls/table/components/table-head';
import { TableHeadCell } from '../../../../core/components/controls/table/components/table-head-cell';
import { TableRow } from '../../../../core/components/controls/table/components/table-row';
import { Table } from '../../../../core/components/controls/table/table';
import { StockDto } from '../../../../core/models/dtos/stock.dto';
import { mwApiInstance } from '../../../../core/services/axios.middleware';
import { getBadgeColorThreePoints } from '../../../../core/utils/points-badge-color.util';
import { AnalysisDto } from '../../models/analyse.dto';
import { MuiAnalyseModal } from '../analyse-modal/analyse-modal-mui';
import { AnalyseScore } from '../analyse-score/analyse-score';

export const DividendAnalyse: React.FC = () => {
  const dispatch = useDispatch();

  const stockData = useSelector<ReduxStore, StockState>((state) => state.stock);
  const stockDto = useSelector<ReduxStore, StockDto | undefined>((state) => state.stock.stockDto);

  /** Set initial rows and load dividend data */
  useEffect(() => {
    if (stockDto?.symbol !== stockData.dividendScore?.symbol) {
      const calculate = async (): Promise<void> => {
        if (stockDto?.symbol) {
          const analysisResponse = await mwApiInstance.get(`/analysis/dividend/${stockDto.symbol}`);
          const dividendAnalysisModels: AnalysisDto[] = analysisResponse.data;

          const scoreModel: ScoreModel = {
            symbol: stockDto.symbol,
            analyseModels: dividendAnalysisModels,
          };

          dispatch(setDividendScore(scoreModel));
        }
      };

      calculate();
    }
  }, [stockDto]);

  return (
    <CardTable title='DIV-Score' headerRight={<AnalyseScore analysisModel={stockData.dividendScore?.analyseModels} multiplicator={3} />}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>Kriterium</TableHeadCell>
            <TableHeadCell align='center'>Wert</TableHeadCell>
            <TableHeadCell align='right'>Punkte</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stockData.dividendScore?.analyseModels.map((row) => (
            <TableRow key={row.criteria}>
              <TableCell>{row.criteria}</TableCell>
              <TableCell align='center'>
                <div className='flex justify-center items-center'>
                  <span className='mr-1'>{row.value}</span>
                  {/* <AnalyseModal modalData={row} /> */}
                  <MuiAnalyseModal modalData={row} />
                </div>
              </TableCell>
              <TableCell align='right'>
                <Badge label={row.points} color={getBadgeColorThreePoints(row.points)}></Badge>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CardTable>
  );
};
