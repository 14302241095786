import React from 'react';

import { SinglePageContainer } from '../../core/components/controls/single-page-container/single-page-container';
import { AuthGuard } from '../../core/guards/auth.guard';
import { DiversificationSection } from './components/diversification-section/diversification-section';
import { DividendChart } from './components/dividend-chart/dividend-chart';
import { PortfolioList } from './components/portfolio-list/portfolio-list';

export const PortfolioModule: React.FC = () => {
  return (
    <AuthGuard redirectToLogin={true}>
      <SinglePageContainer>
        <h2>Portfolio</h2>
        <PortfolioList />
        <h2>Diversifikation</h2>
        <DiversificationSection />
        <h2>Dividenden (Ex-Tag)</h2>
        <DividendChart />
      </SinglePageContainer>
    </AuthGuard>
  );
};
