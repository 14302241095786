import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { StockModule } from './stock.module';

export const StockRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path=':symbol' element={<StockModule />} />
    </Routes>
  );
};
