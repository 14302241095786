import React from 'react';

export const HeaderSection: React.FC = () => {
  return (
    <section className='grid max-w-screen-xl px-4 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12'>
      <div className='mr-auto place-self-center lg:col-span-7'>
        <h1 className='max-w-2xl mb-4 text-3xl font-extrabold leading-none tracking-tight md:text-4xl xl:text-5xl'>
          Finde die besten <span className='text-secondary'>Aktien</span>
        </h1>
        <p className='max-w-2xl mb-6 font-light text-gray-400 lg:mb-8 md:text-lg lg:text-xl'>
          Aktien finden mit Hilfe bewährter Anlagestrategien und langfristig den Index schlagen. Eigene fundierte Anlageentscheidungen
          treffen und von erfolgreichen Investoren lernen.
        </p>
      </div>
      <div className='lg:mt-0 lg:col-span-5 lg:flex'>
        <img src={`${process.env.PUBLIC_URL}/assets/img/analyse-icon.png`} alt='stock-analyse' />
      </div>
    </section>
  );
};
