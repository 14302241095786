import { LocalStorageTypes } from '../models/enums/local-storage.enum';

/** Save value to browser local storage */
export const setItemLocalStorage = (key: LocalStorageTypes, value: string): void => {
  localStorage.setItem(key, value);
};

/** Get local storage value by key */
export const getItemLocalStorage = (key: LocalStorageTypes): string | null => {
  const value = localStorage.getItem(key);
  return value;
};

/** Remove value in local storage */
export const removeItemInLocalStorage = (key: LocalStorageTypes): void => {
  localStorage.removeItem(key);
};
