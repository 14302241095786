import classNames from 'classnames';
import React from 'react';

interface SinglePageContainerProps {
  children: React.ReactNode;
  className?: string;
}

export const SinglePageContainer: React.FC<SinglePageContainerProps> = (props: SinglePageContainerProps) => {
  return <div className={classNames('container mx-auto p-4 max-w-screen-2xl', props.className)}>{props.children}</div>;
};
