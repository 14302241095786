import Parser from 'html-react-parser';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mwApiInstance } from '../../../../core/services/axios.middleware';
import { StockState, setLevScore } from '../../../../redux-store/slices/stock.slice';
import { ReduxStore } from '../../../../redux-store/store';
import { AnalysisDto } from '../../models/analyse.dto';
import { ScoreModel } from '../../models/score.model';
import { AnalyseScore } from '../analyse-score/analyse-score';

import { CardTable } from '../../../../core/components/building-blocks/card-table/card-table';
import { Badge } from '../../../../core/components/controls/badge/badge';
import { TableBody } from '../../../../core/components/controls/table/components/table-body';
import { TableCell } from '../../../../core/components/controls/table/components/table-cell';
import { TableHead } from '../../../../core/components/controls/table/components/table-head';
import { TableHeadCell } from '../../../../core/components/controls/table/components/table-head-cell';
import { TableRow } from '../../../../core/components/controls/table/components/table-row';
import { Table } from '../../../../core/components/controls/table/table';
import { getBadgeColor } from '../../../../core/utils/points-badge-color.util';
import { MuiAnalyseModal } from '../analyse-modal/analyse-modal-mui';
export const LevermannAnalysis: React.FC = () => {
  const dispatch = useDispatch();

  const stockData = useSelector<ReduxStore, StockState>((state) => state.stock);

  /** Initialize component set default values */
  useEffect(() => {
    const loadLevermannAnalysis = async (): Promise<void> => {
      if (stockData.stockDto && stockData.stockDto.symbol !== stockData.levermannScore?.symbol) {
        const response = await mwApiInstance.get(`/analysis/lev/${stockData.stockDto.symbol}`);
        const levermannAnalysisModels: AnalysisDto[] = response.data;

        const scoreModel: ScoreModel = {
          symbol: stockData.stockDto.symbol,
          analyseModels: levermannAnalysisModels,
        };

        dispatch(setLevScore(scoreModel));
      }
    };

    loadLevermannAnalysis();
  }, [stockData.stockDto]);

  return (
    <CardTable title='LEV-Score' headerRight={<AnalyseScore analysisModel={stockData.levermannScore?.analyseModels} />}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>Kriterium</TableHeadCell>
            <TableHeadCell align='center'>Wert</TableHeadCell>
            <TableHeadCell align='right'>Punkte</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stockData.levermannScore?.analyseModels.map((row) => (
            <TableRow key={row.criteria}>
              <TableCell>{row.criteria}</TableCell>
              <TableCell align='center'>
                <div className='flex justify-center items-center'>
                  <span className='mr-1'>{Parser(row.value)}</span>
                  <MuiAnalyseModal modalData={row} />
                </div>
              </TableCell>
              <TableCell align='right'>
                <Badge label={row.points} color={getBadgeColor(row.points)}></Badge>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </CardTable>
  );
};
