import React from 'react';

interface TableHeadCellProps {
  /** Pass React child component */
  children?: React.ReactNode;
  /** Set colspan of table head */
  colspan?: number;
  /** Column alignment */
  align?: 'center' | 'left' | 'right' | 'justify' | 'char' | undefined;
}

export const TableHeadCell: React.FC<TableHeadCellProps> = (props: TableHeadCellProps) => {
  return (
    <th
      className='font-semibold border border-solid border-l-0 border-r-0 border-gray-100 px-6 py-3'
      colSpan={props.colspan}
      align={props.align}
    >
      {props.children}
    </th>
  );
};
