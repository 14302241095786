import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarState, showSnackbar } from '../../../../redux-store/slices/snackbar.slice';
import './copy-clipboard.scss';

interface CopyClipboardProps {
  text: string | undefined;
}

export const CopyClipboard: React.FC<CopyClipboardProps> = (props: CopyClipboardProps) => {
  const dispatch = useDispatch();

  const copyToClipboad = async (): Promise<void> => {
    if (props.text) {
      await navigator.clipboard.writeText(props.text);
      dispatch(
        showSnackbar({
          message: `${props.text} im Zwischenspeicher gespeichert`,
          severety: 'success',
        } as SnackbarState)
      );
    }
  };

  return (
    <button className='copy-clipboard text-gray-400 font-bold text-sm' onClick={() => copyToClipboad()}>
      <span>{props.text}</span>
      <ContentCopyIcon />
    </button>
  );
};
