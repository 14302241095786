import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DialogTitle, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Parser from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Divider } from '../../../../core/components/controls/divider/divider';
import { AnalysisDto } from '../../models/analyse.dto';
import './analyse-model.scss';

interface AnalyseModalProps {
  modalData: AnalysisDto;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogContent-root h3': {
    margin: '10px 0',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps): JSX.Element => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontWeight: 600 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const MuiAnalyseModal: React.FC<AnalyseModalProps> = (props: AnalyseModalProps) => {
  const [modalData, setModalData] = useState<AnalysisDto>(props.modalData);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  useEffect(() => {
    setModalData(props.modalData);
  }, [props]);

  return (
    <div className='analyseModal'>
      <InfoOutlinedIcon onClick={handleOpen} style={{ cursor: 'pointer' }} />

      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
          {modalData.criteria}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <p>{modalData.description ? Parser(modalData.description) : ''}</p>
          <Divider />
          <h2>Punkteverteilung</h2>
          {modalData.pointsDistribution ? Parser(modalData.pointsDistribution) : ''}
          <Divider />
          <h2>Daten</h2>
          {modalData.data && modalData.data.length
            ? modalData.data.map((data, i) => {
                return (
                  <div key={`data-${i}`}>
                    <span className='text-xs'>{Parser(data.title)}</span>
                    <br></br>
                    <span>{Parser(data.value)}</span>
                  </div>
                );
              })
            : 'Keine Daten vorhanden'}

          {modalData.calculationText ? (
            <>
              <Divider />
              <h2>Berechnung</h2>
            </>
          ) : (
            ''
          )}
          {modalData.calculationText ? Parser(modalData.calculationText) : ''}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
