/** Gibt das Währungssymbol zurück */
export const getCurrencySymbol = (curreny: string | undefined): string => {
  if (!curreny) return '';

  switch (curreny) {
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'GBp':
      return '£';
  }

  return curreny;
};
