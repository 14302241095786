import { ResetPasswordDto } from '../models/dtos/reset-password.dto';
import { UserDto } from '../models/dtos/user.dto';
import { mwApiInstance } from './axios.middleware';

export const createNewUser = async (user: UserDto): Promise<UserDto | undefined> => {
  const response = await mwApiInstance.post(`/user/create`, user).catch(() => {
    return undefined;
  });
  return response?.data;
};

export const resetPassword = async (resetPasswordDto: ResetPasswordDto): Promise<UserDto | undefined> => {
  const response = await mwApiInstance.post(`/user/resetPassword`, resetPasswordDto).catch(() => {
    return undefined;
  });
  return response?.data;
};
