/** Return badge color related to points */
export const getBadgeColorThreePoints = (points: number): 'default' | 'success' | 'warning' | 'error' => {
  if (points === 3) return 'success';
  if (points > 0) return 'warning';
  if (points === 0) return 'error';

  return 'default';
};

/** Return positiv and negativ badge color */
export const getBadgeColor = (points: number): 'default' | 'success' | 'warning' | 'error' => {
  if (points > 0) return 'success';
  if (points < 0) return 'error';

  return 'default';
};
