import { mwApiInstance } from '../../../core/services/axios.middleware';
import { AuthorizeResponseDto } from '../models/dtos/authorize-response.dto';
import { AuthorizeDto } from '../models/dtos/authorize.dto';

export const authorize = async (authorize: AuthorizeDto): Promise<AuthorizeResponseDto | undefined> => {
  const response = await mwApiInstance.post(`/auth/authorize`, authorize).catch(() => {
    return undefined;
  });
  return response?.data;
};
