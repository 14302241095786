import classNames from 'classnames';
import React from 'react';

interface DividerProps {
  className?: string;
}

export const Divider: React.FC<DividerProps> = (props: DividerProps) => {
  return <hr className={classNames('divider', props.className)}></hr>;
};
