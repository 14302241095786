import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowDownSvg } from '../../../../../assets/svg/arrowdown.svg';
import { ReactComponent as ArrowUpSvg } from '../../../../../assets/svg/arrowup.svg';
import { TopScorerDto } from '../../../models/dtos/topscorer.dto';
import { SymbolLink } from '../../controls/symbol-link/symbol-link';
import { TableBody } from '../../controls/table/components/table-body';
import { TableCell } from '../../controls/table/components/table-cell';
import { TableHead } from '../../controls/table/components/table-head';
import { TableHeadCell } from '../../controls/table/components/table-head-cell';
import { TableRow } from '../../controls/table/components/table-row';
import { Table } from '../../controls/table/table';
import { TotalScore } from '../../controls/total-score/total-score';
import './topscorer-table.scss';

interface TopScorerTableProps {
  topScorer: TopScorerDto[];
  totalScore: number;
  isLoading: boolean;
}

export const TopScorerTable: React.FC<TopScorerTableProps> = (props: TopScorerTableProps) => {
  const [topScorer, setTopScorer] = useState<TopScorerDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setTopScorer(props.topScorer);
  }, [props.topScorer]);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  return (
    <Table loading={isLoading}>
      <TableHead>
        <TableRow>
          <TableHeadCell>#</TableHeadCell>
          <TableHeadCell>Aktie</TableHeadCell>
          <TableHeadCell>Score</TableHeadCell>
          <TableHeadCell>Aktualisiert</TableHeadCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {topScorer.map((value, index) => (
          <TableRow key={index}>
            <TableCell>
              <div className='flex'>
                {index + 1}
                {(!value.previousScore && value.score) || value.previousScore < value.score ? <ArrowUpSvg className='positiv' /> : <></>}
                {value.previousScore && value.previousScore > value.score ? <ArrowDownSvg className='negativ' /> : <></>}
              </div>
            </TableCell>
            <TableCell>
              <SymbolLink symbol={value.stock.symbol} displayName={value.stock.name} logoUrl={value.stock?.stockCompanyOverview?.website} />
            </TableCell>
            <TableCell>
              <TotalScore reachedPoints={value.score} totalPoints={props.totalScore} />
            </TableCell>
            <TableCell width={35}>{value.updated ? moment(value.updated).format('DD.MM.YYYY') : '-'}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
