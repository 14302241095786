import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as EuropeFlag } from '../../../../../assets/svg/europe-flag.svg';
import { ReactComponent as GermanyFlag } from '../../../../../assets/svg/germany-flag.svg';
import { ReactComponent as UnitedKingdomFlag } from '../../../../../assets/svg/united-kingdom-flag.svg';
import { ReactComponent as UsaFlag } from '../../../../../assets/svg/usa-flag.svg';
import { Card } from '../../../../core/components/controls/card/card';
import { TitleContainer } from '../../../../core/components/controls/title-container/title-container';
import './indice-section.scss';

export const IndiceWidgets: React.FC = () => {
  return (
    <>
      <TitleContainer
        title='Indizes'
        content='Wir analysieren alle Aktien wöchentlich aus folgenden Indizes, schauen Sie sich hier alle Aktien im Detail an.'
      />

      <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4'>
        <Link to={'indice/^GDAXI'}>
          <Card hoverEffect={true}>
            <div className='indice-widget flex flex-col items-center gap-2'>
              <h3 className='text-lg font-bold text-slate-900 sm:text-xl text-center'>GDAXI</h3>
              <GermanyFlag />
              <p className='mt-2 sm:block text-center'>Alle Aktien des Deutschen-Aktien-Index</p>
            </div>
          </Card>
        </Link>

        <Link to={'indice/^TECDAX'}>
          <Card hoverEffect={true}>
            <div className='indice-widget flex flex-col items-center gap-2'>
              <h3 className='text-lg font-bold text-slate-900 sm:text-xl text-center'>TECDAX</h3>
              <GermanyFlag />
              <p className='mt-2 sm:block text-center'>Alle Aktien des TECDAX</p>
            </div>
          </Card>
        </Link>

        <Link to={'indice/^STOXX50E'}>
          <Card hoverEffect={true}>
            <div className='indice-widget flex flex-col items-center gap-2'>
              <h3 className='text-lg font-bold text-slate-900 sm:text-xl text-center'>STOXX50E</h3>
              <EuropeFlag />
              <p className='mt-2 sm:block text-center'>Alle Aktien des EuroStoxx50</p>
            </div>
          </Card>
        </Link>

        <Link to={'indice/^FTSE'}>
          <Card hoverEffect={true}>
            <div className='indice-widget flex flex-col items-center gap-2'>
              <h3 className='text-lg font-bold text-slate-900 sm:text-xl text-center'>FTSE 100</h3>
              <UnitedKingdomFlag />
              <p className='mt-2 sm:block text-center'>Aktien des Financial Times Stock Exchange</p>
            </div>
          </Card>
        </Link>

        <Link to={'indice/^GSPC'}>
          <Card hoverEffect={true}>
            <div className='indice-widget flex flex-col items-center gap-2'>
              <h3 className='text-lg font-bold text-slate-900 sm:text-xl text-center'>S&P 500</h3>
              <UsaFlag />
              <p className='mt-2 sm:block text-center'>Alle Aktien des S&P 500</p>
            </div>
          </Card>
        </Link>

        <Link to={'indice/^DJI'}>
          <Card hoverEffect={true}>
            <div className='indice-widget flex flex-col items-center gap-2'>
              <h3 className='text-lg font-bold text-slate-900 sm:text-xl text-center'>DJI</h3>
              <UsaFlag />
              <p className='mt-2 sm:block text-center'>Alle Aktien des Dow Jones</p>
            </div>
          </Card>
        </Link>
      </div>
    </>
  );
};
