import { NewsDto } from '../models/dtos/news.dto';
import { ScreenerModel } from '../models/interfaces/screener.interface';
import { YahooQuoteSummaryModel } from '../models/interfaces/yahoo/yahoo-quote-summary.model';
import { ScreenerResponse, YahooQuoteSummaryResponse } from '../models/interfaces/yahoo/yahoo.response';
import { mwApiInstance } from './axios.middleware';

/**
 * Search stock, etf or crypto
 * @param search
 * @returns
 */
export const loadAutocomplete = async (search: string): Promise<any> => {
  const searchResponse = await mwApiInstance.get('/yahoo/autocomplete', {
    params: {
      search: search,
    },
  });

  return searchResponse;
};

/**
 * Detailed information for a particular stock
 * @param symbol
 * @param modules
 * @returns
 */
export const loadQuoteSummary = async (symbol: string, modules: string): Promise<YahooQuoteSummaryModel | undefined> => {
  const quoteSummaryResponse = await mwApiInstance.get<YahooQuoteSummaryResponse>(`/yahoo/quoteSummary/${symbol}`, {
    params: {
      modules: modules,
    },
  });

  if (quoteSummaryResponse.data?.quoteSummary?.result[0]) {
    const quoteSummary: YahooQuoteSummaryModel = quoteSummaryResponse.data?.quoteSummary?.result[0];
    return quoteSummary;
  }

  return undefined;
};

export const loadScreener = async (canonicalName: string): Promise<ScreenerModel[]> => {
  const response = await mwApiInstance.get<ScreenerResponse>(`/yahoo/screener`, {
    params: {
      srcIds: canonicalName,
    },
  });

  return response.data.finance.result;
};

export const loadSymbolNews = async (symbol: string): Promise<NewsDto[]> => {
  const response = await mwApiInstance.get<NewsDto[]>(`/yahoo/news/${symbol}`);
  return response.data;
};
