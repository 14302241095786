import { PortfolioDto } from '../../modules/portfolio/models/portfolio.dto';
import { DividendModel } from '../models/dividend.model';
import { StockDto } from '../models/dtos/stock.dto';
import { mwApiInstance } from './axios.middleware';

export const addStockToPortfolio = async (newStock: PortfolioDto): Promise<StockDto | undefined> => {
  const stockResponse = await mwApiInstance.post<StockDto>('/portfolio/stock', newStock).catch(() => {
    return undefined;
  });
  return stockResponse?.data;
};

/** Delete stock from user portfolio */
export const deleteStockFromPortfolio = async (stock: PortfolioDto): Promise<void> => {
  const response = await mwApiInstance.delete(`/portfolio/stock`, {
    data: stock,
  });
  return response.data;
};

/** Load all portfolio stocks */
export const loadPortfolioStocks = async (): Promise<PortfolioDto[]> => {
  const stocksResponse = await mwApiInstance.get<PortfolioDto[]>('/portfolio');
  return stocksResponse.data;
};

export const loadPortfolioDividends = async (): Promise<DividendModel[]> => {
  const dividendResponse = await mwApiInstance.get<DividendModel[]>('/portfolio/dividends');
  return dividendResponse.data;
};
