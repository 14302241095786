import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SinglePageContainer } from '../../core/components/controls/single-page-container/single-page-container';
import { ClientRoutes } from '../../core/models/enums/client-routes.enum';
import { EmailNotice } from './components/email-notice/email-notice';
import { ForgotPassword } from './components/forgot-password/forgot-password';
import { UserLogin } from './components/login/user-login';
import { UserRegistration } from './components/registration/user-registration';

export const UserRoutes: React.FC = () => {
  return (
    <SinglePageContainer>
      <Routes>
        <Route path='*' element={<Navigate to={ClientRoutes.USER_LOGIN} />} />
        <Route path={ClientRoutes.LOGIN} element={<UserLogin />} />
        <Route path={ClientRoutes.REGISTRATION} element={<UserRegistration />} />
        <Route path={ClientRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ClientRoutes.EMAIL_NOTICE} element={<EmailNotice />} />
      </Routes>
    </SinglePageContainer>
  );
};
