import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientRoutes } from '../../../models/enums/client-routes.enum';
import { loadAutocomplete } from '../../../services/yahoo.service';
import './stock-search.scss';

export const StockSearch: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState<any[]>([]);

  const [activeSuggestion, setActiveSuggestion] = useState<number>(0);

  const navigate = useNavigate();

  /** Handle Enter event */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Enter' && suggestions.length) {
        handleSuggestionClick(suggestions[activeSuggestion]);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeSuggestion]);

  /** Handler arrow up and down event */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        // Up arrow key
        setActiveSuggestion((prevActiveSuggestion) => (prevActiveSuggestion > 0 ? prevActiveSuggestion - 1 : suggestions.length - 1));
      } else if (event.key === 'ArrowDown') {
        // Down arrow key
        event.preventDefault();
        setActiveSuggestion((prevActiveSuggestion) => (prevActiveSuggestion < suggestions.length - 1 ? prevActiveSuggestion + 1 : 0));
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [suggestions]);

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    setSearchTerm(event.target.value);

    // Do not send request if value is empty
    if (event.target.value === '') {
      setSuggestions([]);
      return;
    }

    // Perform autocomplete suggestions logic here
    const searchResponse = await loadAutocomplete(event.target.value);

    if (searchResponse?.data?.quotes) {
      setSuggestions(searchResponse.data.quotes);
    } else {
      setSuggestions([]);
    }
  };

  /** Navigate with selected symbol to stock view */
  const handleSuggestionClick = (suggestion: any): void => {
    if (suggestion?.symbol) {
      navigate(`${ClientRoutes.STOCK}/${suggestion.symbol}`);
      clearSearch();
    }
  };

  const clearSearch = (): void => {
    setSearchTerm('');
    setSuggestions([]);
    setActiveSuggestion(0);
  };

  return (
    <div className='relative mx-auto w-auto md:w-2/3 lg:w-1/3'>
      {/* Search Icon */}
      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
        <svg
          aria-hidden='true'
          className='w-5 h-5 text-gray-500'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
            clipRule='evenodd'
          ></path>
        </svg>
      </div>

      {/* Search input */}
      <input
        type='text'
        className='border border-gray-600 rounded-lg text-white text-sm block w-full pl-10 p-2.5 bg-gray-700  placeholder-gray-400'
        placeholder='Suche nach Aktien'
        value={searchTerm}
        onChange={handleInputChange}
        // onBlur={clearSearch}
        required
      />

      {/* Cancel Icon */}
      {suggestions.length ? (
        <button type='button' className='absolute inset-y-0 right-0 flex items-center pr-2' onClick={clearSearch}>
          <svg
            aria-hidden='true'
            className='w-4 h-4 text-gray-400 hover:text-white'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z'
              stroke='currentColor'
              fill='none'
              fillRule='evenodd'
              strokeLinecap='round'
              strokeLinejoin='round'
            ></path>
          </svg>
        </button>
      ) : (
        <></>
      )}

      {/* Suggestion */}
      {suggestions.length > 0 && (
        <ul className='absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg'>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className={classNames('py-2 px-4 cursor-pointer hover:bg-gray-100', index === activeSuggestion ? 'bg-gray-100' : '')}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.displayName ?? suggestion.shortname} ({suggestion.symbol})
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
