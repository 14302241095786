import classNames from 'classnames';
import React from 'react';
import './card.scss';

interface CardProps {
  children?: React.ReactNode;
  className?: string;
  hoverEffect?: boolean;
}

export const Card: React.FC<CardProps> = (props: CardProps) => {
  return (
    <div className={classNames('card', 'flex flex-row', props.hoverEffect === true ? 'transition duration-300 hover:scale-105' : '')}>
      <div
        className={classNames(
          'flex grow justify-center rounded-lg border border-gray-100 p-4 shadow-xl sm:p-2 lg:p-4 bg-white',
          props.className
        )}
      >
        <div className='text-gray-400'>{props.children}</div>
      </div>
    </div>
  );
};
