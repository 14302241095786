import React from 'react';
import { Paragraph } from '../../../../core/components/controls/paragraph/paragraph';

interface IndiceDescriptionProps {
  symbol: string;
}

export const IndiceDescription: React.FC<IndiceDescriptionProps> = (props: IndiceDescriptionProps) => {
  return (
    <>
      {props.symbol === '^GDAXI' ? (
        <div>
          <h1 className='font-bold text-2xl'>Deutscher Aktienindex</h1>
          <Paragraph>
            Der DAX ist der bekannteste Aktienindex in Deutschland und besteht aus den 30 größten und am meisten gehandelten Unternehmen an
            der Frankfurter Wertpapierbörse. Der Index wird von der Deutschen Börse berechnet und bietet Investoren einen Überblick über die
            Entwicklung des deutschen Aktienmarktes. Der DAX wird seit 1988 berechnet und ist eine wichtige Kennzahl für die deutsche
            Wirtschaft und den Aktienmarkt.
            <br></br>
            <br></br>
            Der Index umfasst Unternehmen aus verschiedenen Branchen, wie z.B. der Automobilindustrie, der Finanz- und Versicherungsbranche,
            der Chemieindustrie und der Technologiebranche. Die Gewichtung der einzelnen Unternehmen im Index wird anhand ihrer
            Marktkapitalisierung berechnet, was bedeutet, dass Unternehmen mit einer höheren Marktkapitalisierung einen größeren Anteil am
            Index haben als Unternehmen mit einer niedrigeren Marktkapitalisierung.
          </Paragraph>
        </div>
      ) : (
        <></>
      )}

      {props.symbol === '^STOXX50E' ? (
        <div>
          <h1 className='font-bold text-2xl'>Euro Stoxx 50</h1>
          <Paragraph>
            Der Euro Stoxx 50 ist ein Aktienindex, der die Entwicklung der 50 größten und liquidesten Unternehmen im Euro-Währungsgebiet
            abbildet. Der Index wurde 1998 von der Stoxx Ltd. eingeführt und wird von der Deutschen Börse und der Börse in Paris berechnet.
            <br></br>
            <br></br>
            Die 50 Unternehmen im Index stammen aus verschiedenen Branchen wie z.B. der Automobilindustrie, der Banken- und
            Versicherungsbranche, der Energie- und Rohstoffindustrie sowie der Technologiebranche. Der Index wird nach der
            Marktkapitalisierung gewichtet, was bedeutet, dass Unternehmen mit einer höheren Marktkapitalisierung einen größeren Anteil am
            Index haben als Unternehmen mit einer niedrigeren Marktkapitalisierung.<br></br>
            <br></br> Der Euro Stoxx 50 wird oft als wichtiger Indikator für die Entwicklung des europäischen Aktienmarktes betrachtet und
            wird von vielen Investoren weltweit beobachtet und als Grundlage für Anlageentscheidungen genutzt.
          </Paragraph>
        </div>
      ) : (
        <></>
      )}

      {props.symbol === '^DJI' ? (
        <div>
          <h1 className='font-bold text-2xl'>Dow Jones</h1>
          <Paragraph>
            Der Dow Jones ist ein Aktienindex, der die Wertentwicklung der 30 größten und bekanntesten börsennotierten Unternehmen in den
            USA abbildet. Der Index wurde 1896 eingeführt und ist nach Charles Dow, dem Gründer der Dow Jones & Company, benannt.
            <br></br>
            <br></br>
            Die 30 Unternehmen im Dow Jones Industrial Average (kurz DJIA) stammen aus verschiedenen Branchen wie z.B. der Finanz- und
            Versicherungsbranche, der Technologiebranche, der Einzelhandelsbranche sowie der Industrie- und Konsumgüterbranche. Der Dow
            Jones wird nach dem Preis gewichtet, was bedeutet, dass Unternehmen mit einem höheren Aktienkurs einen größeren Einfluss auf den
            Index haben als Unternehmen mit einem niedrigeren Aktienkurs.
            <br></br>
            <br></br>
            Der Dow Jones wird oft als wichtiger Indikator für die Entwicklung des US-Aktienmarktes betrachtet und wird von vielen
            Investoren weltweit beobachtet und als Grundlage für Anlageentscheidungen genutzt.
          </Paragraph>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
