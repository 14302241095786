import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

interface BadgeProps {
  label: string | number;
  color?: 'default' | 'success' | 'warning' | 'error';
}

export const Badge: React.FC<BadgeProps> = (props: BadgeProps) => {
  const [textColor, setTextColor] = useState<string>('text-gray-50');
  const [backgroundColor, setBackgroundColor] = useState<string>('bg-gray-500');

  useEffect(() => {
    switch (props.color) {
      case 'success':
        setTextColor('text-green-50');
        setBackgroundColor('bg-green-500');
        break;
      case 'warning':
        setTextColor('text-orange-50');
        setBackgroundColor('bg-orange-500');
        break;
      case 'error':
        setTextColor('text-red-50');
        setBackgroundColor('bg-red-500');
        break;

      default:
        setTextColor('text-gray-50');
        setBackgroundColor('bg-gray-500');
    }
  }, [props.color]);

  return (
    <span
      className={classNames(
        textColor,
        backgroundColor,
        textColor,
        `text-xs font-semibold inline-block py-1 px-2 uppercase rounded last:mr-0 mr-1`
      )}
    >
      {props.label}
    </span>
  );
};
