import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SymbolLink } from '../../../../core/components/controls/symbol-link/symbol-link';
import { deleteStockFromPortfolio, loadPortfolioStocks } from '../../../../core/services/portfolio.service';
import { setPortfolio } from '../../../../redux-store/slices/portfolio.slice';
import { ReduxStore } from '../../../../redux-store/store';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Tooltip } from '@mui/material';
import { TableBody } from '../../../../core/components/controls/table/components/table-body';
import { TableCell } from '../../../../core/components/controls/table/components/table-cell';
import { TableHead } from '../../../../core/components/controls/table/components/table-head';
import { TableHeadCell } from '../../../../core/components/controls/table/components/table-head-cell';
import { TableRow } from '../../../../core/components/controls/table/components/table-row';
import { Table } from '../../../../core/components/controls/table/table';
import { TotalScore } from '../../../../core/components/controls/total-score/total-score';
import { getTotalScore } from '../../../../core/utils/top-scorer.util';
import { PortfolioDto } from '../../models/portfolio.dto';
import './portfolio-list.scss';

export const PortfolioList: React.FC = () => {
  const dispatch = useDispatch();
  const portfolioStocks = useSelector<ReduxStore, any[]>((state) => state.portfolio.portfolioDtos);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadUserPortfolioStocks = async (): Promise<void> => {
    const portfolio: PortfolioDto[] = await loadPortfolioStocks();
    dispatch(setPortfolio(portfolio));
  };

  /** Initial load of portfolio */
  useEffect(() => {
    if (portfolioStocks.length) {
      setIsLoading(false);
    }
  }, [portfolioStocks]);

  /** Delete stock from portfolio */
  const removeStock = async (symbol: string): Promise<void> => {
    await deleteStockFromPortfolio({
      userId: 1,
      stock: {
        symbol: symbol,
      },
    });

    loadUserPortfolioStocks();
  };

  return (
    <Table loading={isLoading}>
      <TableHead>
        <TableRow>
          <TableHeadCell align='left'>Asset</TableHeadCell>
          <TableHeadCell>DIV</TableHeadCell>
          <TableHeadCell>HGI</TableHeadCell>
          <TableHeadCell>LEV</TableHeadCell>
          <TableHeadCell></TableHeadCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {portfolioStocks.map((value: PortfolioDto, index) => (
          <TableRow key={index}>
            <TableCell>
              <SymbolLink symbol={value.stock.symbol} logoUrl={value.stock.stockCompanyOverview?.website} displayName={value.stock.name} />
            </TableCell>

            {/* TopScorer */}
            {value.stock.topScorer?.map((totalScore, index) => {
              return (
                <TableCell key={index}>
                  <TotalScore reachedPoints={totalScore.score} totalPoints={getTotalScore(totalScore.type)} />
                </TableCell>
              );
            })}

            {/* Actions */}
            <TableCell width={20}>
              <Tooltip title='Asset entfernen'>
                <IconButton onClick={() => removeStock(value.stock.symbol)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
