import classNames from 'classnames';
import Parser from 'html-react-parser';
import React from 'react';

interface TitleContainerProps {
  title: string;
  content?: string;
  className?: string;
}

export const TitleContainer: React.FC<TitleContainerProps> = (props: TitleContainerProps) => {
  return (
    <section className={classNames('justify-center text-center flex flex-wrap my-10', props.className)}>
      <div className='w-full md:w-10/12 md:px-4 sm:w-12/12 sm:px-2'>
        <h2 className='font-extrabold text-2xl lg:text-4xl'>{props.title}</h2>
        <p className='text-lg text-neutral-500'>{props.content ? Parser(`${props.content}`) : ''}</p>
      </div>
    </section>
  );
};
