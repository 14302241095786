import React from 'react';
import { Link } from 'react-router-dom';
import { CompanyAvatar } from '../company-avatar/company-avatar';
import './symbol-link.scss';

interface SymbolLinkProps {
  symbol: string;
  displayName?: string;
  logoUrl?: string;
}

export const SymbolLink: React.FC<SymbolLinkProps> = (props: SymbolLinkProps) => {
  return (
    <Link className='symbolLink' to={`/stock/${props.symbol}`} title={props.displayName || props.symbol}>
      {<CompanyAvatar imageUrl={props.logoUrl} width='30' />}
      <span>{props.displayName ? props.displayName : props.symbol}</span>
    </Link>
  );
};
