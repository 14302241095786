import './stock-header.scss';

import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../core/components/controls/button/button';
import { CompanyAvatar } from '../../../../core/components/controls/company-avatar/company-avatar';
import { CopyClipboard } from '../../../../core/components/controls/copy-clipboard/copy-clipboard';
import { StockDto } from '../../../../core/models/dtos/stock.dto';
import { addStockToPortfolio } from '../../../../core/services/portfolio.service';
import { setPortfolio } from '../../../../redux-store/slices/portfolio.slice';
import { SnackbarState, showSnackbar } from '../../../../redux-store/slices/snackbar.slice';
import { UserState } from '../../../../redux-store/slices/user.slice';
import { ReduxStore } from '../../../../redux-store/store';
import { PortfolioDto } from '../../../portfolio/models/portfolio.dto';

export const StockHeader: React.FC = () => {
  const dispatch = useDispatch();

  const stockDto = useSelector<ReduxStore, StockDto | undefined>((state) => state.stock.stockDto);
  const portfolioStocks = useSelector<ReduxStore, any[]>((state) => state.portfolio.portfolioDtos);
  const user = useSelector<ReduxStore, UserState>((state) => state.user);

  const onClickAddStockToPortfolio = async (): Promise<void> => {
    if (stockDto) {
      const portfolioDto: PortfolioDto = {
        stock: stockDto,
      };

      const portfolioResponse = await addStockToPortfolio(portfolioDto);

      if (portfolioResponse) {
        const updatedPortfolioDtos = [...portfolioStocks, portfolioResponse];
        dispatch(setPortfolio(updatedPortfolioDtos));

        dispatch(
          showSnackbar({
            message: `${stockDto.name} wurde ins Portfolio hinzufügen`,
            severety: 'success',
          } as SnackbarState)
        );
      }
    }
  };

  const isStockInPortfolio = (): boolean => {
    return portfolioStocks.some((stock) => {
      return stock.stock.symbol === stockDto?.symbol;
    });
  };

  return (
    <div className='bg-white md:py-10 py-5 mb-5'>
      <div className='container mx-auto px-4 max-w-screen-2xl'>
        <div className='flex grow justify-between'>
          <div className='flex flex-row'>
            <div className='companyAvatar'>
              <CompanyAvatar imageUrl={stockDto?.stockCompanyOverview?.website} />
            </div>
            <div className='titleContainer'>
              <h1 className='m-0'>{stockDto?.name}</h1>
              <div className='subTitleContainer'>
                <CopyClipboard text={stockDto?.symbol} />
              </div>
            </div>
          </div>
          {user.isAuthorized && portfolioStocks.length && !isStockInPortfolio() ? (
            <div className='flex items-center'>
              <Button onClick={() => onClickAddStockToPortfolio()} icon={<AddIcon />}>
                Zum Portfolio hinzufügen
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
