import React from 'react';

interface CardTableProps {
  title: string;
  headerRight?: React.ReactNode;
  children: React.ReactNode;
}

export const CardTable: React.FC<CardTableProps> = (props: CardTableProps) => {
  return (
    <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded'>
      <div className='rounded-t mb-0 px-4 py-3 border-0'>
        <div className='flex flex-wrap items-center'>
          <div className='relative w-full px-4 max-w-full flex-grow flex-1'>
            <h3 className='font-semibold text-base text-blueGray-700'>{props.title}</h3>
          </div>
          <div className='relative w-full px-4 max-w-full flex-grow flex-1 text-right'>{props.headerRight}</div>
        </div>
      </div>

      {props.children}
    </div>
  );
};
