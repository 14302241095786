/* eslint-disable react/react-in-jsx-scope */
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './app/redux-store/store';

import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './app/app.routes';
import './index.scss';

const container = document.getElementById('root');

if (container) {
  const root: Root = createRoot(container);

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </Provider>
  );
}
