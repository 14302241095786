import React from 'react';
import { SinglePageContainer } from '../../core/components/controls/single-page-container/single-page-container';
import { Screener } from './components/screener';
import { ScreenerTypes } from './enum/screener-type.enum';

export const ScreenerModule: React.FC = () => {
  return (
    <SinglePageContainer>
      <h1 className='font-bold text-2xl '>Screener</h1>
      <p className='text-gray-400'>
        Screener (auch Aktien-Screener oder Filter genannt) helfen dir Aktien oder andere Finanzinstrumente auf der Grundlage bestimmter
        Kriterien zu filtern und zu analysieren.
      </p>
      <Screener type={ScreenerTypes.MOST_ACTIVES} />
      <p>
        <b>&quot;Höchste Volumen&quot;</b>:{' '}
        <span className='text-gray-400'>
          das sind Aktien die das höchste Handelsvolumen, das an einem bestimmten Tag oder in einem bestimmten Zeitraum an einer Börse oder
          an einem Handelsplatz für ein bestimmtes Wertpapier erzielt wurde. Das Handelsvolumen gibt an, wie viele Aktien oder andere
          Wertpapiere an einem Tag oder in einem bestimmten Zeitraum gehandelt wurden.
        </span>
      </p>
      <Screener type={ScreenerTypes.DAY_GAINERS} />
      <p>
        <b>&quot;Tagesgewinner&quot;</b>:{' '}
        <span className='text-gray-400'>sind Aktien die am aktuellen Handelstag die höchsten Kursgewinne erzielt haben.</span>
        <br></br>
        <br></br>
        <span className='text-gray-400'>
          Ein Tagesgewinner-Screener kann verschiedene Kriterien als Filter verwenden, um die Liste der Gewinner einzugrenzen, wie z.B. den
          prozentualen Kursgewinn, das Handelsvolumen oder die Marktkapitalisierung. In der Regel kann man sich auch weitere Informationen
          zu den Gewinner-Aktien wie den aktuellen Kurs, das Tageshoch und -tief, das Kurs-Gewinn-Verhältnis (KGV) oder die
          Dividendenrendite anzeigen lassen.
        </span>
        <br></br>
        <br></br>
        <span className='text-gray-400'>
          Es ist jedoch wichtig zu beachten, dass die Kursgewinne an einem Tag nicht unbedingt eine langfristige Wertentwicklung
          widerspiegeln und nicht zwangsläufig zu einer Kaufempfehlung führen sollten. Es ist daher ratsam, weitere Analysen durchzuführen,
          um die Aktien auf ihre langfristige Wertentwicklung und ihre Fundamentaldaten zu überprüfen, bevor man eine Anlageentscheidung
          trifft.
        </span>
      </p>
      <Screener type={ScreenerTypes.DAY_LOSERS} />
      <p>
        <b>&quot;Tagesverlierer&quot;</b>:{' '}
        <span className='text-gray-400'>sind Aktien die am aktuellen Handelstag die größten Verluste gemacht haben.</span>
      </p>

      <br></br>
      <p className='text-gray-400'>
        Ein Tagesverlierer-Screener kann verschiedene Kriterien als Filter verwenden, um die Liste der Verlierer einzugrenzen, wie z.B. den
        prozentualen Kursverlust, das Handelsvolumen oder die Marktkapitalisierung. In der Regel kann man sich auch weitere Informationen zu
        den Verlierer-Aktien wie den aktuellen Kurs, das Tageshoch und -tief, das Kurs-Gewinn-Verhältnis (KGV) oder die Dividendenrendite
        anzeigen lassen.
      </p>

      <br></br>
      <p className='text-gray-400'>
        Es ist jedoch wichtig zu beachten, dass die Kursverluste an einem Tag nicht unbedingt eine langfristige Wertentwicklung
        widerspiegeln und nicht zwangsläufig zu einer Verkaufsempfehlung führen sollten. Es ist daher ratsam, weitere Analysen
        durchzuführen, um die Aktien auf ihre langfristige Wertentwicklung und ihre Fundamentaldaten zu überprüfen, bevor man eine
        Anlageentscheidung trifft.
      </p>
    </SinglePageContainer>
  );
};
