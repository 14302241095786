import React, { useContext } from 'react';
import { ActiveTabContext } from '../context/active-tab.context';

interface TabPanelProps {
  value: any;
  children: React.ReactNode;
}

export const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { activeTab } = useContext(ActiveTabContext);

  return <div>{props.value === activeTab ? <>{props.children}</> : null}</div>;
};
