import React from 'react';
import { Spinner } from '../spinner/spinner';
import { TableBody } from './components/table-body';
import { TableCell } from './components/table-cell';
import { TableRow } from './components/table-row';

export interface TableProps {
  children: React.ReactNode;
  loading?: boolean;
}

export const Table: React.FC<TableProps> = (props: TableProps) => {
  return (
    <div className='overflow-x-auto w-full rounded-b-lg'>
      <table className='mx-auto w-full whitespace-nowrap bg-white overflow-hidden '>
        {props.loading ? (
          <TableBody>
            <TableRow>
              <TableCell colspan={4}>
                <Spinner />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <>{props.children}</>
        )}
      </table>
    </div>
  );
};
