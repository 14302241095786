import { createContext } from 'react';

export interface ActiveTabContextProps {
  activeTab: string | null;
  setActiveTab: (value: any) => void;
}

export const ActiveTabContext = createContext<ActiveTabContextProps>({
  activeTab: '0',
  setActiveTab: () => undefined,
});
